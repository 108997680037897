// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".save-status_save-now_c2ybV {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/menu-bar/save-status.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,eAAe;AACnB","sourcesContent":[".save-now {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"save-now": "save-status_save-now_c2ybV",
	"saveNow": "save-status_save-now_c2ybV"
};
module.exports = ___CSS_LOADER_EXPORT___;
