// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.watermark_sprite-image_mZ61T {\n    margin: auto;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    max-width: 48px;\n    max-height: 48px;\n    opacity: 0.35;\n}\n", "",{"version":3,"sources":["webpack://./src/components/watermark/watermark.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":["\n.sprite-image {\n    margin: auto;\n    user-select: none;\n    max-width: 48px;\n    max-height: 48px;\n    opacity: 0.35;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sprite-image": "watermark_sprite-image_mZ61T",
	"spriteImage": "watermark_sprite-image_mZ61T"
};
module.exports = ___CSS_LOADER_EXPORT___;
